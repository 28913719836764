// 设置 rem 函数
function setRem () {
	// 基准大小，一般为设计稿宽度的1/10或1/20，以此来计算页面的总rem，例如此处页面的总宽度为750/32rem
	const baseSize = 32
	// 缩放，此处分母填写的750为设计稿宽度
	const scale = document.documentElement.clientWidth / 1920;
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.addEventListener('resize', () => setRem());
