import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ebad47e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout_footer" }
const _hoisted_2 = {
  class: "footer_nav_area",
  style: {"min-width":"1280px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navListData, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "footer_nav",
          key: item.id
        }, [
          (!item.isNotShow?.includes(_ctx.locale))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "footer_nav_title",
                to: item.url
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`${item.name}`)), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (ic) => {
            return (_openBlock(), _createElementBlock("div", {
              key: ic.id
            }, [
              (!ic.isNotShow?.includes(_ctx.locale))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: ic.url
                  }, {
                    default: _withCtx(() => [
                      (ic.name === 'nav.EVIBB' && _ctx.locale != 'ja')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(`nav.EVIBB01`)) + _toDisplayString(_ctx.$t(`nav.EVIBB02`)), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t(`${ic.name}`)), 1))
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}