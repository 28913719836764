<template>
  <el-container id='layout'>
    <el-header class="header_area">
      <div class="header_lang_area">
        <div class="header_lang">
          <span @click="changeLanguage('en')" style="border-right: 1px solid #fff;">English</span>
          <span @click="changeLanguage('zhCn')" style="border-right: 1px solid #fff;">中文</span>
          <span @click="changeLanguage('ja')">日本语</span>
        </div>
      </div>
      <header-view />
    </el-header>
    <el-main class="main_area">
      <router-view/>
      <footer-view class="footer_view_area"/>
    </el-main>
    <el-footer class="footer_area" :class="{ 'fixed_bottom': isFooterFixed }" >
      <div style="display: flex;align-items: center;">
        &copy;&nbsp;2018&nbsp;{{ $t('companyName') }}&nbsp;&nbsp;&nbsp;&nbsp;
        <a v-if="locale === 'zh_CN'" style="color: #cccccc;" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备11026994号-1</a>
        <a href="https://www.beian.suzhou.gov.cn/license?id=1186174914034401342" target="_blank">
          <img src="http://www.beian.suzhou.gov.cn/resources/images/marker/szicbok.gif" style="width: 31px;margin-left: 10px;" alt="">
        </a>
      </div>
      <div>
        <span style="padding: 0 10px;">Tel:&nbsp;86-512-6616 5970</span>
        <!-- <span>Fax:&nbsp;86-512-6657 2805</span> -->
        <span>Email:&nbsp;infor@wdint.com</span>
      </div>
    </el-footer>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import HeaderView from '@/components/header.vue'
import FooterView from '@/components/footer.vue'; 

export default defineComponent({
  name: 'layout',
  components: { HeaderView, FooterView},
  setup(){
    const route = useRoute();
    const { proxy } = getCurrentInstance() as any;
    const { locale } = useI18n()

    const changeLanguage = (val:string) => {
      if(val === 'zhCn') {
        proxy.$i18n.locale = 'zh_CN'
      } else if(val === 'en') {
        proxy.$i18n.locale = 'en'
      } else if(val === 'ja') {
        proxy.$i18n.locale = 'ja'
      }
    }

    const isFooterFixed = ref(false);

    const checkFooterPosition = () => {
      const appHeight = document.getElementById('layout')?.clientHeight || 0;
      const windowHeight = window.innerHeight;
      isFooterFixed.value = appHeight < windowHeight;
    };

    watch(()=>route.path,()=>{
      setTimeout(() => {
        checkFooterPosition();
        window.addEventListener('resize', checkFooterPosition);
      },30)
    })

    onMounted(() => {
      checkFooterPosition();
      window.addEventListener('resize', checkFooterPosition);
    });

    return {
      locale,
      changeLanguage,
      isFooterFixed,
    }
  }
});
</script>

<style scoped lang="less">
@media screen and (min-width: 768px) {
  /* PC端特有样式 */
  .header_area{
    width: 100%;
    padding: 0;
    position: relative;
    height: 123px;
    .header_lang_area{
      width: 100%;
      height: 41px;
      min-width: 1280px;
      background-color: #2c3e50;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .header_lang{
        width: 1280px;
        text-align: right;
        font-size: 12px;
        span {
          display: inline-block;
          padding: 0 10px;
          cursor: pointer;
        }
      }
    }
  }
  .main_area{
    padding: 0;
  }
  .footer_area{
    width: 100%;
    min-width: 1280px;
    height: 80px;
    background-color: #252525;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #cccccc;
    line-height: 24px;
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  /* 手机端特有样式 */
  .header_area{
    width: 100%;
    padding: 0;
    position: relative;
    height: 7vh;
    .header_lang_area{
      display: none;
    }
  }
  .main_area{
    padding: 0;
    .footer_view_area {
      display: none;
    }
  }
  .footer_area{
    width: 100%;
    min-width: 90vw;
    height: 12rem;
    background-color: #252525;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: #cccccc;
    line-height: 2rem;
    padding: 0;
  }
  .fixed_bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
  