export default {
    companyName:'苏州西典新能源电气股份有限公司',
    home:'首页',
    nav:{
        about:'关于我们',
        aboutCompany:'公司简介',
        developmentHistory:'发展历程',
        memorabilia:'公司大事记',
        operation:'运营方式与能力',
        certificationSystem:'认证体系',
        contact:'联系方式',
        careerDevelopment:'职业发展',
        joinUs:'加入我们',
        proServices:'产品与服务',
        powder:'粉末涂装式叠层母排',
        edgeClosed:'边缘封闭式叠层母排',
        EVIBB01:'EV-CCS',
        EVIBB02:'集成母排(CCS)',
        complete:'为客户制定的解决方案',
        industrySolutions:'行业解决方案',
        solarPower:'光伏发电',
        windPower:'风力发电',
        transportation:'轨道交通',
        powerGrid:'电力电网',
        electricalVehicle:'新能源汽车',
        energyStorage:'电化学储能',
        other:'其他工业领域',
        engineering:'技术与创新',
        laminatedBusbarDesign:'如何设计一个好的叠层母排',
        analysis:'分析与测试',
        design:'计算工具',
        laminated:'叠层母排设计辅助计算',
        parallel:'两平行母线的电感计算',
        quality:'质量保证',
        qualityAssurance:'西典的质量保证',
        technology:'西典的工艺技术',
        capability:'西典的制造能力',
        investorRelations:'投资者关系',
        companyAnnouncement:'公司公告',
        investorCommunication:'投资者交流',
        investorServices:'投资者服务'
    },
    index:{
        indexBannerBottom:'西典新能源电气(WDI), 是一家在中国苏州的股份有限公司。西典公司以设计和制造为主，为国内外客 户提供电力产品-叠层母线排，以及系统的技术解决方案。我们服务的客户来自新能源领域(太阳能，风 能)，交通领域，电力系统领域以及其他工业等领域。公司拥有包括电力电子、机械和制造工艺领域等 经验丰富的专业技术团队，可以根据客户的要求进行产品的研发，优化设计。',
        index3ProDesc:'西典的母排产品采用先进和专有的技术制造。我们在母排产品及工艺技术上拥有多项专利...',
        index3IndustryDesc:'长期以来，西典针对不同行业应用要求，提供最可靠的解决方案...',
        index3Engineering:'我们的技术团队凭借丰富的知识与经验，持续为客户提供产品设计和优化...',
        loadMore:'MORE INFO',
    },
    about:{
        aboutDesc01:'苏州西典新能源电气股份有限公司主要从事电连接技术的研究、开发和应用，致力于成为复合母排、电池连接系统创新技术的领导者。',
        aboutDesc02:'公司主要产品电池连接系统和复合母排广泛应用于新能源汽车、电化学储能、轨道交通、工业变频、新能源发电等领域， 公司已经积累了宁德时代、比亚迪、法拉电子、中国中车、阳光电源、国轩高科、西屋制动、罗克韦尔、东芝三菱等优质客户。 在新能源汽车领域，公司产品应用于比亚迪、特斯拉、蔚来、理想、小鹏、长城、赛力斯等整车厂的热销车型。',
        aboutDesc03:'公司自设立以来即深耕热压合领域，凭借持续创新不断丰富电连接产品种类及应用领域，创造性地将复合母排热压合工艺应用于电池连接系统的生产制造过程中， 推动行业朝轻量化、集成化方向发展。',
        aboutDesc04:'截至目前，公司共有苏州、成都两个生产基地，苏州基地已全面投产、成都基地正在建设中。',
    },
    operation:{
        title:'我们的运营方式与能力',
        desc01:'- 叠层母线排的领先制造商',
        desc02:'- 参与客户的项目，提供叠层母排的优化设计',
        desc03:'- 专有快速打样技术，缩短新产品的开发周期',
        desc04:'- 在国内外有贴近客户的销售与产品服务队伍',
        title01:'持续改善 追求完美',
        desc05:'西典公司是获得 IRIS （国际铁路标准） 和TS16949体系认证的公司，高质量的产品和服务是西典公司存在和持久发展的基础。我们致力于持续改进我们的产品和服务，满足及超越客户的需求。',
        title02:'我们最终的目标是客户100%的满意！',
    },
    careerDevelopment:{
        carDesc:'西典公司从最开始就把持续改进，不断创新的理念融入到我们的设计和生产。公司的发展和成功离不开一个高水准，团结向上，勇于创新的团队。',
        carDesc01:'为我们的员工提供可靠的薪资福利，理想的工作环境，和学习发展的机会是公司的长期重要目标。',
        carDesc02:'我们欢迎各方面的人才加入西典，同我们的员工一道，随着公司的发展而成长。',
        carDesc03:'请标明您想应聘的部门和职位, 并通过以下方式联系我们：',
        carDesc03TEL:'TEL: 86-512-66165970',
        carDesc03Email:`Email: infor{'@'}wdint.com`
    },
    contact:{
        companyName:'苏州西典新能源电气股份有限公司',
        address:'地址：中国 苏州 高新区金枫路353号',
        zipCode:'邮编：215151',
        phone:'电话：86-512-66165970',
        email:`邮箱：infor{'@'}wdint.com`,
        website:'网站：www.wdint.com',
        workTime:'工作时间：周一至周五，8:30～17:00 (北京时间)',
    },
    proServe:{
        title01:'什么是叠层母排？',
        title01Desc:'叠层母排是一种多层复合结构连接排。与传统导线联接方法相比，低感母排可以大大降低线路的杂散电感，降低开关器件的过电压，作为功率单元中的"超级电路板"，使功率单元在大电流的工作状态中发挥最大的能效。',
        title02:'西典叠层母排的优点',
        title02Desc01:'- 更少的总体成本',
        title02Desc02:'- 无错误的安装',
        title02Desc03:'- 很高的可靠性和安全性',
        title02Desc04:'- 量身定做的模块式结构，便于安装和现场服务',
        title02Desc05:'- 简洁紧凑的设计，节省内部空间',
        title02Desc06:'- 增加分布电容',
        title02Desc07:'- 以更低的电压降实现高电流承载能力',
        title02Desc08:'- 通常比电缆更容易散热冷却，更小的温升',
        title02Desc09:'- 更低的阻抗',
        title02Desc10:'- 更低的电感(特别是对IGBT的应用，降低由于电压击穿而引起的电器元件损坏概率)',
        title03:'西典叠层母排产品线',
    },
    powder:{
        powderCon01:'采用高性能环氧树脂，对单片母排进行涂装绝缘处理，最后通过西典压合技术将涂装好的母排压合成整体。',
        powderCon02:'西典的专有的涂装工艺技术，可严格控制绝缘树脂涂层的厚度。根据不同应用领域的电性能要求，绝缘涂层的厚度可从0.2 mm 达到 3 mm。',
        powderCon03:'西典的粉末涂装式叠层母排具有平滑光亮的外观，良好的耐腐蚀性，优越的电性能。',
        powderCon04:'对于高电压，大电流结构相对复杂的要求有更好的适应性。',
    },
    edgeClosed:{
        edgeClosedCon01:'多层导体的层与层之间和外表面分别配置不同厚度的绝缘膜，通过西典的压合技术，将母排压合成型。',
        edgeClosedCon02:'西典设计和制造的边缘封闭式叠层母排，采用UL认证的高性能绝缘膜。母排的最大尺寸可达 1900 mm x 600 mm。',
    },
    flex:{
        flexTitle_01:'EV-CCS',
        flexTitle_02:'电池管理解决方案',
        flexTitle01:'(Integrated Bus Bar for Battery)',
        flexDesc01:'又称做CCS, 是 PCB/FPC /FFC(信号线路）和 LBB (电力传输）相结合的一体化的集成方案。',
        flexDesc02:'电动汽车进入规模化生产阶段，生产环节的高效率、自动化要求越来越高。西典的EV-CCS 集成母排解决方案，针对电动汽车的特殊技术要求和应用，仅通过一块母排就能实现电池间的动力传输，信号采集和电芯保护，减少了人工组装，降低了接线错误，满足汽车生产高度自动化的要求。',
        flexDesc03:'EV-CCS 集成母排 (CCS) 电动汽车动力系统整体解决方案。>>>',
        flexDesc04:'解更多EV-CCS 集成母排 (CCS) 电化学储能整体解决方案，敬请浏览 >>>',
    },
    complete:{
        completeDesc01:'凭借在电力电子，机械，材料等方面的专业知识和丰富的经验，我们的工程师可根据不同的应用要求 - 电性能，空间结构，工作条件（湿度，工作温度，污染等级等），为客户提供叠层母排的设计和高附加价值的技术支持服务。',
        completeDesc02:'常用的几种绝缘母排方案，及其各种方案的特点和适应性总结如下。',
        completeTable00:'产品类型',
        completeTable01:'PET膜边缘封闭叠层母排',
        completeTable02:'Nomex膜边缘封闭叠层母排',
        completeTable03:'环氧树脂涂装叠层母排',
        completeTable10:'通常工作电流(A)',
        completeTable11:'10~1000',
        completeTable12:'10~1000',
        completeTable13:'10~2000',
        completeTable20:'通常工作电压(V)',
        completeTable21:'380~4000',
        completeTable22:'380~4000',
        completeTable23:'380~4000',
        completeTable30:'长期连续工作温度(℃)',
        completeTable31:'130',
        completeTable32:'125',
        completeTable33:'130',
        completeTable40:'爬电距离',
        completeTable41:'较小（可以通过增加爬电槽或粘接绝缘条改善爬电距离）',
        completeTable42:'较小（可以通过增加爬电槽或粘接绝缘条改善爬电距离）',
        completeTable43:'较大',
        completeTable50:'耐湿性',
        completeTable51:'好',
        completeTable52:'差',
        completeTable53:'好',
        completeTable60:'产品的复杂程度',
        completeTable61:'适用于比较简单，平面或者有少量折弯的设计',
        completeTable62:'适用于比较简单，平面的设计，但膜覆盖处不能折弯',
        completeTable63:'可以加工比较复杂的形状',
        completeTable70:'通常导体厚度(mm)',
        completeTable71:'建议≤4mm',
        completeTable72:'建议≤4mm',
        completeTable73:'建议2~10',
    },
    industrySolutions:{
        title:'叠层母线排的市场及应用',
        inSolutionDesc01:'- 新能源领域 (如太阳能逆变器，风能变流器) ',
        inSolutionDesc02:'- 通讯领域 (如通讯基站，配电系统，服务器，大型主机)',
        inSolutionDesc03:'- 交通领域 (如机车，电车)',
        inSolutionDesc04:'- 其他功率转换领域',
    },
    pv:{
        pvDesc01:'长期以来西典一直在为我们的客户 - 光伏逆变器制造商提供高性能的解决方案。从西班牙的阳光地带到中国的西北戈壁都有大量的西典母排在安全稳定地工作。',
        pvDesc02:'光伏行业面临持续降低单位发电成本，提高系统效率，和长期工作可靠性的挑战。同时由于行业具有周期性，季节性的特点，在出货高峰期，对供应链的反应速度和供货能力要求非常高。',
        pvDesc03:'西典长期与客户共同合作开发，从设计的源头降低成本。我们先进的工艺技术和制造能力，快速响应能力，为您的生产线提供强有力的支持。',
        pvProDesc01:'粉末涂装母排，长期连续工作温度可达130度。所有的铜接头的外围都是被绝缘树脂包裹的，此款母排的最小爬电距离达到了30mm，是在光伏领域的典型应用。',
        pvProDesc02:'采用西典专利技术，使这款母排在保证发热量平衡的同时使母排的重量最轻，成本最低。',
    },
    wind:{
        windDesc01:'风力发电面临高成本、复杂的环境（风雨雷电，沙尘）等挑战，特别是海上风电具有高腐蚀和高污染等级的特殊海洋环境特点，需要母排长期保持正常工作状态，具有较高的可靠性 。',
        windDesc02:'凭借对风电行业多年的设计经验，西典针对海上风电，陆地高海拔风电，以及低风速(小于5.5m/S)风场的应用环境特点，提供了大量叠层母排最优化解决方案。',
        windProDesc01:'在大功率海上风电变流器的母排设计中，复杂的三电平拓扑结构，较高的工作电压，与产品的抗腐蚀性方面的高要求是一个需要解决的问题。',
        windProDesc02:`电容母排采用了边缘封闭式的母排设计，连接处采用了正-零-正，与负零负的结构，在保证电气间隙与爬电距离的同时，极大地减少了电感。`,
        windProDesc0202:'IGBT母排采用了绝缘粉末喷涂的结构，使电性能特别是极与极之间的爬电距离得到了保障。 而且各个接头之间的距离较近，使产品的寄生电感大为降低。',
    },
    traffic:{
        trafficDesc01:'轨道交通设备的现场工作环境非常恶劣。振动与冲击，环境温度与湿度，海拔高度，耐腐蚀性等对母排的耐受性和可靠性等都提出了更高的要求。特别是铁路行业已经进入客运高速、货运重载和电气化的时代，列车必须更加安全，可靠。',
        trafficDesc02:'轨道交通设备对承载的部件体积、重量有非常高的要求。驱动牵引变流器，承受的尖峰电流和过电压都比较高。',
        trafficDesc03:'我们的技术团队在高电压，大电流领域拥有丰富的设计经验。我们的设计和制造严格按照IRIS (国际铁路标准)的高标准体系要求来实现。',
        trafficDesc04:'西典参与了“和谐号”与“复兴号”列车的变流柜复合母排产品设计、开发和制造，实现国产替代。',
        trafficDesc05:'京张铁路2022东奥会专列：西典全程参与了变流柜复合母排的设计，为北京冬奥会的顺利举办贡献了一份力量。',
        trafficDesc06:'川藏铁路，因其地势海拔高（5100m），对机车牵引变流柜的复合母排设计提出更严苛要求, 西典按期完成了这个项目母排的设计工作，顺利通过测试并批量交付。',
        trafficProDesc01:'一款新型机车牵引变流器的母排设计。额定工作电压2800V，额定电流1080A。在保证电性能的前提下，有效解决了模块的重量问题。',
        trafficProDesc02:`用于牵引变流器的母排，设计时采用了边缘封闭式母排与粉末涂装式母排组合的方法，在满足电气性能的前提下，有效节省了空间，解决了客户原有设计空间不足的难题。`,
        trafficProDesc03:'用于牵引机车的辅助变流器中，喷涂厚度2mm，耐压9600V。',
    },
    power:{
        powerTitle:`电力电网`,
        powerDesc01:'随着新能源发电量所占比重的增加，以及无数终端感性负载的存在，电网的稳定性面临严峻考验。通过电力输配电和终端用户两个环节改善电能质量，柔性直流输电、无功补偿装置、有源滤波器以及变频器等技术得到广泛应用。',
        powerDesc02:'对应的叠层母排要求有别于其他领域，特点：大电压、大电流、较高的局部放电要求以及产品高可靠性，西典是少数几家可以满足客户上述要求的叠层母排厂家，并且在产品设计方面处于行业领先地位。',
        powerProDesc01:'环氧树脂以其优越的绝缘性能, 耐热性能和机械性能广泛地应用于需要高可靠性的输配电行业。在输配电领域，西典的粉末涂装母排代替了原来的裸铜排，减小了电气间隙对空间距离的要求，在有限的空间里节省了宝贵的空间资源。',
        powerProDesc02:`这是一款用于SVG高压无功补偿装置的母排。在紧凑的空间内，采用西典专利的封边技术，为客户赢得更大的结构运用空间；采用西典封边专利技术，额外增加了母排IGBT引脚处的爬电距离。`,
    },
    ecar:{
        ecarDesc01:'电动汽车行业发展迅速。从混合动力到纯电动汽车，动力总成的电气化趋势，更高的效率要求，更远的续航里程，以及在恶劣条件下的长期使用可靠性等，是电动汽车不断面临的技术挑战。',
        ecarDesc02:'电动汽车动力系统的不断研发和技术更新，产业的自动化和集成化趋势，对零部件供应商的设计配合能力，以及零部件的品质和性能提出更高的要求。另外电动汽车目前成本居高，在设计源头降低成本，是我们的共同目标。',
        ecarDesc03:'西典很早进入电动汽车领域，与国内外客户合作开发，针对电动汽车的特殊技术要求和应用，提供了从电机驱动控制，电源转换分配，到电池管理的全方位解决方案。我们在电动汽车领域的母排应用，积累了丰富的技术经验和多项专利成果。',
        ecarProDesc0101:'EV-CCS 电池管理解决方案 (Integrated Bus Bar for Battery)',
        ecarProDesc0102:'又称做CCS, 是 PCB/FPC /FFC(信号线路）和 LBB (电力传输）相结合的一体化的集成方案。',
        ecarProDesc0103:'-集成了温度传感器和电压信号采集',
        ecarProDesc0104:'-集成了连接器',
        ecarProDesc0105:'-集成了保险丝保护等贴装器件',
        ecarProDesc0106:'-消除了接线错误',
        ecarProDesc0107:'-减少了焊接和组装',
        ecarProDesc0108:'-空间更紧凑，体积更小',
        ecarProDesc0109:'-更优越可靠的性能',
        ecarProDesc0110:'-高度集成化，适应汽车自动化批量生产',
        ecarProDesc0201:`西典母排驱动控制系统的解决方案`,
        ecarProDesc0202:`-最小的电感`,
        ecarProDesc0203:`-可适用于复杂的电力控制与连接`,
        ecarProDesc0204:`-结构紧凑，体积更小`,
        ecarProDesc0205:`-多种绝缘方式的解决方案 （环氧树脂，PET，PI膜等等）`,
        ecarProDesc0206:`-可适应更高的温度要求`,
        ecarProDesc0207:`-适应更严酷的环境（油，盐雾等）`,
    },
    es:{
        esDesc01:'电化学储能为代表的新型储能, 目前正处于由商业化初期步入规模化发展的关键阶段。目前储能电池领域仍较多使用老一代线束产品；随着储能电池领域对电芯采样要求的提升，集成度更高的CCS方案将快速替代传统线束方案。西典将充分利用动力电池领域的成功经验，在储能电池领域实现快速增长。',
        esDesc02:'除CCS外，边缘封闭式压合母排LBB（Laminated Bus Bar）作为电化学储能系统中储能变流器（Power Conversion System）的核心子系统之一，配合储能变流器实现控制蓄电池的充电和放电，进行交直流变换，以及在无电网情况下直接为交流负荷供电等功能。西典具有近20年边缘封闭式压合母排LBB开发生产经验，所生产的边缘封闭式压合母排具有更少的总体成本，很高的可靠性和安全性，节省内部空间，降低杂散电感，降低尖峰电压保护IGBT，更低的阻抗，无错误的安装，增加分布电容，以更低的电压降实现高电流承载能力，通常比电缆更容易散热冷却，更小的温升等优点。',
        esPro01Title:'',
        esProDesc01Desc01:'',
        esProDesc01Desc02:'',
        esProDesc01Desc03:'',
        esPro02Title:'',
        esProDesc02Desc01:'',
        esProDesc02Desc02:'',
        esProDesc02Desc03:'',
        esProDesc02Desc04:'',
    },
    other:{
        otherDesc01:'工业变频器广泛应用于电力，冶金，石油，化工，中央空调等行业。效率、能源的提升已经成为工业领域不断追求的目标。叠层母排在工业变频领域的应用是一种新的趋势。相对于传统的裸铜排而言，叠层母排具有低电感，优异的绝缘性能和耐侯性的特点。同时能够节省内部空间，有效地保障产品长期工作的可靠性，极大降低产品的后期维护费用。',
        otherDesc02:'针对工业领域应用的不同要求，西典提供有针对性的叠层母排解决方案。',
        otherProDesc01:'这款工业变频器母排，依据环氧树脂粉末包覆性的特点，避免了绝缘膜热压预留封边距离的要求，从而减少IGBT接入端处的杂散电感。',
        otherProDesc0102:'依据粉末涂装母排无设计空间结构限制的特点，在狭窄的空间内，满足客户苛刻的爬电距离要求。',
        otherProDesc02:'用于通讯机柜中的母排，由7组引出脚和一个大功率电源插头组成。把48V直流电由一个集中的供电单元分配到各个独立的用电模块。',
    },
    technology:{
        technologyTitle:'专业的技术团队',
        technologyDesc01:'西典公司拥有包括加拿大注册工程师，博士和硕士等在内的资深设计队伍，在电力电子，机械，材料等方面拥有丰富的知识和工程设计经验。',
        technologyDesc02:'我们的工程师可根据您的要求，为您提供最优秀的设计和完整的解决方案，协助您以最快的速度将新产品投放到市场。',
        technologyDesc03:'西典凭借特有的设计优势，为客户提供产品设计优化。使产品从设计的源头就做到更低的成本，和更优越的产品性能。从而使我们客户的产品在市场上更具竞争优势。',
        technologyTitle01:'产品的设计与研发',
        technologyDesc04:'- 新产品从概念设计到批量生产',
        technologyDesc05:'- 产品的重新设计以提高性能或降低成本',
        technologyDesc06:'- 制造方法与工艺过程的研究与改进',
        technologyDesc07:'- 快速打样 Quick Prototyping 专有技术，缩短新产品的开发周期',
    },
    design:{
        designTitle:'如何设计一个好的叠层母排',
        designTitle01:'设计的主题思想',
        designDesc01:'叠层母排在IGBT与支撑电容之间提供了一个低电感的连接.　所以叠层母排的设计也要综合考虑到IGBT的摆放, IGBT驱动电路的位置, 电容的位置, 散热器等情况. 要满足电性能要求，散热要求，结构布置要求等。',
        designTitle02:'电感对系统的影响：一个好的设计需要使母排的电感最小',
        designTitle03:'减少系统的温升',
        designDesc02:'变频器或逆变器系统的温升过高会影响系统的可靠性和寿命，甚至烧毁变频器。母排作为系统 中关键元器件之一，温升是母排设计中的一个重要考虑因素。',
        designDesc03:'母排的温升设计，需要从母排的发热和散热两方面综合考虑。提高电流的均匀性，改变导体的厚度, 增加母排的散热面积。通过最优化的设计，提供满足性能的最经济的产品。',
        designTitle04:'满足电性能的要求（耐压，局部放电，爬电距离等）',
        designDesc04:'在母排设计中，通过导体和绝缘材料的选择，增加爬电距离等各种方法，结合母排先进的工艺和制造技术，满足母排的电性能要求。',
        designTitle05:'可制造性，成本',
        designDesc05:'母排设计时要考虑母排制造的具体工艺。客户在设计时往往更注重母排在功率模块系统的功能和作用，而忽略母排本身的可制造性，从而会增加母 排工艺的复杂程度和制作成本。一个优秀的母排设计制造商，可以在设计方面为客户提供更多的增值服务。',
        designTitle06:'安装性能',
        designDesc06:'好的母排设计需要母排满足在应用场合的空间结构要求，和机械性能要求。'
    },
    test:{
        testTitle01:'温升的分析与测试', 
        testDesc01:'在母排设计初期，我们可以通过模拟分析及对母排的温升测试，对母排的设计参数加以验证。此项试验可以在西典试验室，通过完善的温升测试设备完成。',
        testDesc02Title:`特别说明：`,
        testDesc02:'即使母排的温升测试分析结果都证明母排的温升在设计要求的范围内， 我们还是建议客户把母排安装在最终的产品内,在系统满负荷运转的情况下，用热成像仪重新扫描母排的温升。因为母排安装时与其它发热元件的距离,母排附近的空气流通，IGBT的温升等因素都会影响到母排的温升。',
        testTitle02:'电感测试',
        testDesc03:'在样品制作完成后，可通过对母排的电感测试，对母排的设计参数加以验证。因为叠层母排的电感只有几nH 到几十nH, 对这种微量电感的测试，可在西典试验室的精密测试设备上完成。',
        testDesc04:'我们还配备有其他先进的开发工具，和电性能，机械性能等相关测试设备，协助工程研发团队进行母排的设计和开发，为客户提供最经济的设计，和性能优异的产品。',
    },
    c1:{
        c1Title:'叠层母排设计辅助计算工具',
        c1FormLabel01:'绝缘层介电常数k',
        c1FormLabel02:'绝缘层厚度a(mm)',
        c1FormLabel03:'母排层数',
        c1FormLabel04:'铜板厚度t(mm)',
        c1FormLabel05:'铜板长度l(mm)',
        c1FormLabel06:'铜板宽度b(mm)',
        c1FormLabel07:'导体允许运行温升(℃)',
        c1FormLabel08:'母排测试电压(V)AC',
        c1FormSubmit:'计算',
        c1FormCancel:'重置',
        c1TableTitle:'计算结果',
        c1Table01:'母排垂直放置交流允许电流(A)',
        c1Table02:'母排水平放置交流允许电流(A)',
        c1Table03:'母排垂直放置直流允许电流(A)',
        c1Table04:'母排水平放置直流允许电流(A)',
        c1Table05:'电感L(nH)',
        c1Table06:'电容C(pF)',
        c1Table07:'交流耐压测试时的漏电流(mA)',
        c1TableDesc:`本计算结果仅作设计前粗略参考，具体以母排的实际结构以及工况等来定。请联系我们的工程师：infor{'@'}wdint.com`,
    },
    c2:{
        c2TableLabel01:'母排中心距d(mm)',
        c2TableLabel02:'母排宽度b(mm)',
        c2TableLabel03:'母排厚度h(mm)',
        c2TableLabel04:'母排长度l(mm)',
        c2TableResult:'电感L(nH)',
        c2TableSubmit:'计算',
        c2TableCancel:'重置',
        c2TableDescTitle:'备注：',
        c2TableDesc01:'1：母排中心距需大于母排宽度，否则计算结果有误',
        c2TableDesc02:'2：母排厚度需小于母排宽度，否则计算结果有误',
        c2TableDesc03:`本计算结果仅作设计前粗略参考，具体以母排的实际结构以及工况等来定。请联系我们的工程师：infor{'@'}wdint.com`,
    },
    certificationSystem:{
        breadTitle:'质量保证',
        title:'西典的质量保证',
        cerDesc:'西典拥有耐压测试，局部放电测试，接触电阻测试，温升测试，电感测试，激光尺寸扫描检测等完善的检验测试手段和设备，保证交付给客户高质量的产品。',
        cerDesc01:'先进的现场无纸化的文件管理系统，使母排的小批量，多品种生产中的图纸文件管理变得准确， 轻松。',
        cerDesc02:'每一个产品有唯一的条码，实现产品从原材料，各个加工工序，组装压合,及最后的测试的全过程质量追溯。',
        cerDesc03:'由于公司的出色表现， 多次被客户评为优秀供应商， 技术创新奖等。',
        title01:'西典是 ISO 9001, IRIS 和 TS16949 认证的公司',
        cerDesc04:'西典公司获得 IRIS （国际铁路标准） 和TS16949体系认证，标志着我们在产品的全程质量管理，产品生命周期管理，和项目管理等方面居于国际先进水平。',
    },
    technique:{
        techniqueTitle:'领先的工艺技术和制造能力',
        techniqueDesc01:'持续创新，追求卓越的理念贯穿于西典公司母排产品的整个生命周期 - 从产品的设计，工艺和制造技术，产品的测试以及产品的长期使用，可靠性全过程。',
        techniqueDesc02:'西典在专用压合，Z 型和 L型折弯， 高压绝缘环氧树脂粉末喷， 快速样品制造等工艺技术方面，处于行业领先地位。',
        techniqueTitle01:'样品开发技术',
        techniqueDesc03:'西典公司采用创新性的 Quick Prototyping 柔性快速制样技术（西典专利技术*）， 极大地帮助客户缩短样品的开发时间， 使您的新产品能尽快地推向市场，同时能帮助您减少项目前期的投入和成本。',
        techniqueTitle02:'压合折弯技术',
        techniqueDesc04:'先进和专有的叠层母排自动压合设备，使压合的工艺参数得到精准的控制，保证稳定的产品质量和性能。',
        techniqueDesc05:'专有的 Z 型和 L 型压合折弯技术（西典专利技术*），减少了传统多次高温压合对绝缘层的损伤，提高了产品的局部放电性能。',
        techniqueTitle03:'母排的制造能力',
        techniqueDesc06:'我们的设备和生产线包括： CNC 数控加工，工装及模具制造，钣金加工，折弯，焊接，环氧树脂涂装，压合，组装，测试等。',
        techniqueDesc07:'除电镀以外, 所有的工艺过程都由内部完成，实现对叠层母排的内部控制和产品的全面质量保证。',
    },
    relation:{
        phone:'电话：0512-6616-5979',
        email:`邮箱：IR{'@'}wdint.com`
    },
    login:{
        email:'电子邮箱：',
        password:'登录密码：',
        code:'验证码：',
        submit:'登录',
        cancel:'重置'
    },
    register:{
        email:'电子邮箱：',
        password:'登录密码：',
        submitPassword:'确认密码：',
        contacts:'联系人：',
        company:'公司：',
        address:'地址：',
        mobile:'手机：',
        phone:'电话：',
        fax:'传真：',
        code:'验证码：',
        submit:'注册',
        cancel:'重置',
    },
    joinUs:{
        joinUsCity:'工作地点 >',
        joinUsTitle:'职位类别 >'
    }
}